import dynamic from 'next/dynamic'
import _ from 'lodash'
const Wrap = dynamic(() => import('@/component/layout/Wrap'))
import { contentHome } from '@/content/cms/strapi.cms'
import {
   _convertArraySingleObject,
   _convertArraySingleObjectWithAsync,
   _convertSEOStrapi,
   _convertSingleObject,
} from '@/helper/manageParameter'
const HeadPage = dynamic(() => import('@/component/layout/HeadPage'))
const HeadPageSlider = dynamic(() =>
   import('@/component/layout/HeadPageSlider')
)
import SectionTitle from '@/component/page/home/SectionTitle'
import SectionInfoPackage from '@/component/page/home/SectionInfoPackage'
import SectionTabStory from '@/component/page/home/SectionTabStory'
import SectionTabStoryNew from '@/component/page/home/SectionTabStoryNew'
import SectionBannerAndPartner from '@/component/page/home/SectionBannerAndPartner'
import SectionFrequently from '@/component/page/home/SectionFrequently'
import { contentBlogs } from '@/content/cms/wordpress.cms'
import { dataSliders } from '@/config/dummyData'
import { inDevEnvironment } from '@/helper/checkEnvMode'

const Home = ({ content = {}, blogs = [] }) => {
   const sliders = inDevEnvironment
      ? dataSliders
      : content?.slide_header?.data || []

   return (
      <>
         <Wrap
            content={content}
            seo={{
               title: 'GlobalXtreme - Fiber Broadband, Wireless, TV & Phone',
               ..._convertSEOStrapi(content.seo || content.data_seo),
            }}
            isCanonicalLocale>
            {!_.isEmpty(sliders) ? (
               <HeadPageSlider content={sliders} />
            ) : (
               <HeadPage
                  content={content.header_page}
                  isCTA={false}
                  isVideo={content.header_page.isVideo}
               />
            )}

            <SectionTitle content={content.section_one_main} />

            {/* <SectionTabStoryNew
               content={content.section_two_main}
               tabs={content.section_two_tabs || []}
            /> */}

            <SectionInfoPackage
               main={content.section_three_main}
               contentPackages={content.section_three_packages || []}
               contentFaq={content.section_three_faq}
            />

            <SectionBannerAndPartner
               main={content.section_four_main}
               features={content.section_four_features}
               partnerships={content.section_four_partnerships}
               blogs={blogs}
            />

            <SectionFrequently
               content={content?.section_faq?.accordion_faq || {}}
            />
         </Wrap>
      </>
   )
}

Home.getInitialProps = async (ctx) => {
   const { locale } = ctx
   let content = {}
   let blogs = []

   await contentHome(locale)
      .then(async (resData) => {
         if (resData?.data?.attributes) {
            content = _convertSingleObject(resData)

            content.section_two_tabs = _convertArraySingleObject(
               content.section_two_tabs
            )

            content.section_three_packages = content.section_three_packages
               ? await _convertArraySingleObjectWithAsync(
                    content.section_three_packages
                 )
               : []

            if (content?.section_three_faq?.accordion_faq?.points) {
               content.section_three_faq.accordion_faq.points =
                  _convertArraySingleObject(
                     content.section_three_faq.accordion_faq.points
                  )
            }

            content.section_four_features = _convertArraySingleObject(
               content.section_four_features
            )
            content.section_four_partnerships = _convertArraySingleObject(
               content.section_four_partnerships
            )

            if (content?.section_faq?.accordion_faq?.points) {
               content.section_faq.accordion_faq.points =
                  _convertArraySingleObject(
                     content.section_faq.accordion_faq.points
                  )
            }
         }
      })
      .catch((err) => {
         console.log('content: ', err)
      })

   await contentBlogs()
      .then((resData) => {
         blogs = resData
      })
      .catch((err) => {
         console.log('blogs: ', err)
      })

   return { content, blogs }
}
export default Home
